import type { lngShort } from '../i18n.utils'

export function formatTitleUrl(title: string) {
	// Remove leading and trailing whitespaces
	let sanitizedTitle = title.trim()

	// Convert to lowercase
	sanitizedTitle = sanitizedTitle.toLowerCase()

	// Replace special characters with their ASCII equivalents
	sanitizedTitle = sanitizedTitle
		.replace(/[àáâãäå]/g, 'a')
		.replace(/[ç]/g, 'c')
		.replace(/[èéêë]/g, 'e')
		.replace(/[ìíîï]/g, 'i')
		.replace(/[ñ]/g, 'n')
		.replace(/[òóôõö]/g, 'o')
		.replace(/[ùúûü]/g, 'u')
		.replace(/[ýÿ]/g, 'y')

	// Remove any remaining special characters
	sanitizedTitle = sanitizedTitle.replace(/[^a-zA-Z0-9 ]/g, '')

	// Replace spaces with dashes
	sanitizedTitle = sanitizedTitle.replace(/\s/g, '-')

	// Replace multiple dashes with a single dash
	sanitizedTitle = sanitizedTitle.replace(/-+/g, '-')

	return sanitizedTitle
}

export function getEventDateUrl(id: string, title: string, lng?: lngShort) {
	if (!lng) {
		return `/events/${id}/${formatTitleUrl(title)}`
	}
	return `${lng === 'sv' ? '' : `/${lng}`}/events/${id}/${formatTitleUrl(title)}`
}
